import { getImageUrl } from "../../helpers/getImageUrl";
import { getFrontendUrl } from "../../helpers/utils";
import { Page } from "../../types/Page";

export function getRichSnippet(page: Page) {
  const common = {
    "@type": "WebPage",
    "@id": getFrontendUrl(`/#${page.slug}`),
    name: page.name,
    url: getFrontendUrl(`/${page.slug}`),
    image: getImageUrl(page.featured_image),
    about: page.about,
    description: page.description,
    keywords: page.keywords,
    isFamilyFriendly: page.family_friendly,
    inLanguage: "en-US",
    isPartOf: {
      "@id": getFrontendUrl("/#website")
    }
  };

  if (page.has_products) {
    return {
      ...common,
      "@type": "DataCatalog",
      accessMode: "visual",
      author: {
        "@id": getFrontendUrl("/#person")
      }
    };
  }

  return common;
}
