import React from "react";
import { Menu } from "../types/Menu";
import { css } from "styled-components";
import { Colors } from "../branding";
import { Wrapper } from "../components/Wrapper";
import { Button } from "../components/Button";
import { Row, Spacing } from "../helpers/layout";
import { ItalicTitle } from "../typography";
import { Link, getLinkParams } from "./Link";
import Router, { useRouter } from "next/router";
import { useResponsive } from "../context/ResponsiveContext";
import {
  IN_PAGE_MENU_LEVEL,
  IN_PAGE_MENU_MOBILE_LEVEL,
} from "../helpers/zIndex";
import { PlainSelect } from "../forms/SelectField";
import { isTheSameUrl } from "../helpers/utils";
import { useCommonData } from "../context/CommonDataContext";

export function InPageMobileMenu(props: {
  menu: Menu | null;
  name: string;
  notSticky?: boolean;
}) {
  const { menu, notSticky } = props;
  const router = useRouter();
  const menuItems = menu ? menu.items : [];
  const currentItem = menuItems.find((item) =>
    isTheSameUrl(item.url, router.asPath)
  );
  const shopItem = menuItems.find((item) =>
    item.name.toLowerCase().match("shop")
  );

  const name =
    props.name.length > 27 ? props.name.slice(0, 24) + "..." : props.name;

  return (
    <div
      css={css`
        position: relative;
        transition: top 0.2s ease-in-out 0s;
        transform: translate3d(0px, 0px, 0px);
        padding: ${Spacing.m} 0;
        background: ${Colors.very_light_grey};
        z-index: ${IN_PAGE_MENU_MOBILE_LEVEL};

        ${!notSticky &&
        css`
          position: sticky;
          top: 0;

          body.header-pinned & {
            top: 70px;
          }
        `}
      `}
    >
      <Wrapper type="header">
        <Row align="center">
          <div
            css={css`
              flex: 1 0 20%;
              position: relative;
            `}
          >
            {menu && (
              <PlainSelect
                fullBorder
                placeholder={name}
                value={currentItem ? currentItem.uuid : ""}
                options={menu.items.map((item) => {
                  return {
                    value: item.uuid,
                    label: item.name,
                  };
                })}
                onChange={(itemUUID) => {
                  const item = menu.items.find(
                    (item) => item.uuid === itemUUID
                  );

                  if (item) {
                    const { url, new_window } = item;
                    const { href, as } = getLinkParams(url, new_window);

                    Router.push(href, as);
                  }
                }}
              />
            )}
          </div>
          {shopItem && (
            <div
              css={css`
                flex: 0 0 auto;

                button {
                  min-height: 36px;
                  line-height: 36px;
                }
              `}
            >
              <Button
                label={"Shop"}
                style={
                  currentItem && currentItem.uuid === shopItem.uuid
                    ? "black-border"
                    : "default"
                }
                onClick={() => {
                  const { url, new_window } = shopItem;
                  const { href, as } = getLinkParams(url, new_window);

                  Router.push(href, as);
                }}
                type="button"
              />
            </div>
          )}
        </Row>
      </Wrapper>
    </div>
  );
}

export function InPageDesktopMenu(props: {
  menu: Menu | null;
  name: string;
  notSticky?: boolean;
}) {
  const { menu, notSticky } = props;
  const router = useRouter();
  const { isTablet } = useResponsive();
  const { settings } = useCommonData();

  return (
    <nav
      css={css`
        padding: ${Spacing.l} 0;
        background: ${Colors.very_light_grey};
        transition: top 0.2s ease-in-out 0s;
        transform: translate3d(0px, 0px, 0px);

        ${!notSticky &&
        css`
          position: sticky;
          top: 0;
          z-index: ${IN_PAGE_MENU_LEVEL};

          body.header-pinned & {
            top: 70px;
          }
        `}
      `}
    >
      <Wrapper type="header">
        <Row justify={"space-between"} align="center">
          <div
            css={css`
              flex: 1 0 10%;
            `}
          >
            <ItalicTitle>{props.name}</ItalicTitle>
          </div>
          <div
            css={css`
              flex: 0 0 auto;
            `}
          >
            {menu && (
              <Row gutter={isTablet ? Spacing.l : Spacing.xl} align="center">
                {menu.items.map(({ name, url, new_window }, index) => {
                  const sameUrl = isTheSameUrl(router.asPath, url);
                  const label =
                    settings.gallery_focus &&
                    name.toLowerCase() === "shop the series"
                      ? "View the Series"
                      : name;

                  if (index === menu.items.length - 1) {
                    return (
                      <div
                        css={css`
                          flex: 0 0 auto;
                        `}
                        key={name}
                      >
                        <Button
                          key={name}
                          label={label}
                          style={sameUrl ? "black-border" : "default"}
                          onClick={() => {
                            const { href, as } = getLinkParams(url, new_window);

                            Router.push(href, as);
                          }}
                          type="button"
                        />
                      </div>
                    );
                  }

                  return (
                    <div
                      key={name}
                      css={css`
                        flex: 0 0 auto;
                        font-size: 12px;
                        text-transform: uppercase;
                        letter-spacing: 0.15em;
                        color: ${Colors.black};

                        ${isTablet &&
                        css`
                          font-size: 11px;
                          letter-spacing: 0.1em;
                        `}
                      `}
                    >
                      <Link href={url} external={new_window} isActive={sameUrl}>
                        {label}
                      </Link>
                    </div>
                  );
                })}
              </Row>
            )}
          </div>
        </Row>
      </Wrapper>
    </nav>
  );
}

export function InPageMenu(props: {
  menu: Menu | null;
  name: string;
  notSticky?: boolean;
}) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return <InPageMobileMenu {...props} />;
  }

  return <InPageDesktopMenu {...props} />;
}
