import { Page } from "../types/Page";
import { NextPage, NextPageContext } from "next";
import { notAuthorised } from "../request";
import { CommonData } from "../types/CommonData";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { getCommonData } from "../helpers/common-data";
import { BlocksRenderer } from "../components/BlocksRenderer";

import { getAnnouncements } from "../helpers/getAnnouncements";
import { Announcement } from "../types/Announcement";
import { SearchFilters } from "../components/SearchFilters";
import { useState, useEffect } from "react";
import { Product } from "../types/Product";
import { ProductsBlockRenderer } from "../components/blocks/ProductsBlockRenderer";
import { InPageMenu } from "../components/InPageMenu";
import { useFilters } from "../helpers/useFilters";
import { getRichSnippet } from "../helpers/rich-snippets/page";
import { getFrontendUrl } from "../helpers/utils";
import { isBlogPost } from "../helpers/post";
import { useChatbox } from "../helpers/useChatbox";
import { SEOTags } from "../components/SEOTags";
import Error from "./_error";

export const PageDetail: NextPage<{
  slug: string;
  data: Page | null;
  commonData: CommonData;
  announcements: Announcement[];
}> = (props) => {
  const { data } = props;
  const { filters, setFilters } = useFilters(props.slug, true);
  const [results, setResults] = useState<Product[]>([]);

  useChatbox(data ? data.show_chat : false);

  useEffect(() => {
    const getResults = async (slug: string) => {
      try {
        const args = [];

        if (filters.length > 0) {
          args.push(`filters=${filters.join(",")}`);
        }

        const request = await notAuthorised.request<Product[]>({
          method: "GET",
          url: `/api/frontend/pages/${slug}/products/?${args.join("&")}`,
        });

        setResults(request.data);
      } catch (e) {
        console.log(e);
      }
    };

    if (data && filters.length > 0) {
      getResults(data.slug);
    }
  }, [filters, data]);

  if (!data) {
    return (
      <Error
        commonData={props.commonData}
        announcements={props.announcements}
        statusCode={404}
      />
    );
  }

  const { blocks, has_products } = data;

  const isHomepage = data.slug === "homepage";

  const url = isHomepage ? getFrontendUrl("") : getFrontendUrl(`/${data.slug}`);
  const hasResults = filters.length > 0 && results.length > 0;

  return (
    <>
      <SEOTags
        title={data.name}
        description={data.description}
        keywords={data.keywords}
        type={isHomepage ? "website" : "article"}
        url={url}
        featuredImage={data.featured_image}
        richSnippets={getRichSnippet(data)}
      />
      <Header
        headerStyle={data.header_style}
        announcements={props.announcements}
      />
      <article>
        <BlocksRenderer
          blocks={blocks}
          afterFirstBlock={
            <>
              {has_products && data.tags.length > 0 && (
                <SearchFilters
                  showTitle
                  background="white"
                  tags={data.tags}
                  currentFilters={filters}
                  onFiltersChange={(filters) => {
                    setFilters(filters);
                  }}
                  isSticky
                  inWrapper
                />
              )}
              {data.menu && (
                <InPageMenu menu={data.menu} name={data.menu.name} />
              )}
            </>
          }
          hideRestBlocks={hasResults}
        />
      </article>
      {hasResults && (
        <ProductsBlockRenderer
          block={{
            type: "products",
            content: {
              layout: "masonry",
              products: {
                value: results,
              },
            },
          }}
        />
      )}
      <Footer />
    </>
  );
};

interface Context extends NextPageContext {
  query: {
    slug: string;
  };
}

PageDetail.getInitialProps = async (ctx: Context) => {
  // If blog post - redirect to blog
  if (isBlogPost(ctx.query.slug)) {
    if (ctx.res) {
      ctx.res.writeHead(301, {
        Location: `/blog/${ctx.query.slug}`,
      });

      ctx.res.end();
    }

    return {} as any;
  }

  // Else fetch page with that slug

  const slug = ctx.query.slug ? ctx.query.slug : "homepage";
  let data = null;

  try {
    const response = await notAuthorised.request<Page>({
      method: "GET",
      url: `/api/frontend/pages/${slug}/`,
    });

    data = response.data;
  } catch (error) {
    if (ctx.res && error.response) {
      ctx.res.statusCode = error.response.status;
    }
  }

  const commonData = await getCommonData(ctx.req);
  const announcements = await getAnnouncements(slug);

  return {
    slug,
    data: data,
    commonData: commonData,
    announcements,
  };
};

export default PageDetail;
