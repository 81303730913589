const blogPostsSlugs = [
  "the-story-behind-a-young-goddess-minidsayo",
  "the-story-behind-by-the-sea",
  "issue-4-the-fashion-issue-ii",
  "the-story-behind-sail-majesty-at-sea-over-the-rail",
  "the-story-behind-in-the-realm-of-legends-endless-dream",
  "what-makes-a-drew-doggett-print",
  "the-expedition",
  "seeking-what-counts",
  "the-art-of-culture",
  "coming-into-focus",
  "the-story-behind-desert-songs-adorned",
  "issue-3-uncovering-humanity",
  "1000yearoldlovestory",
  "issue-2-the-fashion-issue",
  "issue-1-nature-delivers-the-unexpected",
  "the-story-behind-in-the-realm-of-legends-through-the-night",
  "the-story-behind-desert-songs-a-moment-in-shade",
  "top-5-places-visit-american-west-arent-grand-canyon",
  "the-story-behind-in-transits-point-reyes",
  "africa-collection-objects-travel",
  "story-behind-sable-islands-legend",
  "tradition-of-warriorhood",
  "fashion-art-meet-desert",
  "history-12-meter-yachts",
  "urgent-yet-timeless-5-images-remind-drew-whats-important",
  "fashion-himalayas-end",
  "celebrating-omo-people",
  "band-rebels-white-horses-camargue-series-released",
  "band-rebels-white-horses-camargue-video-released",
  "national-parks",
  "in-transit-series-release",
  "why-i-chose-color-ephemeral-shores",
  "ephemeral-shores-series-released",
  "shadows-alight-collection-released",
  "drew-previews-new-series-in-photographer-magazine-interview",
  "behind-the-scenes-with-drew-on-sable-island",
  "ddpgoeswest",
  "history-of-the-js",
  "why-i-chose-sossusvlei",
  "dunes-abstract-expressions-video-honored-with-bronze-prize-at-nikon-photo-contest",
  "on-press-with-sail-majesty-at-sea",
  "majesty-at-sea-collection-video-released",
  "dunes-landscapes-evolving-video",
];

export function isBlogPost(slug: string) {
  return blogPostsSlugs.includes(slug);
}
